<template>
 <div class="context_whole">
  <van-nav-bar
 style="background-color:rgb(48, 65, 86);"
 title="个人中心"
 fixed="true"
  />

   <van-cell-group>
     <van-cell  label="详细信息"  >
       <div style="height: 15%;margin-top: 15%">
         <van-image
             round
             width="5rem"
             height="5rem"
             :src="this.url"
         />
<!--         <van-image :src="item.url" style="width: 50%"/>-->
        <div style="height: 10px;font-size:20px;float:right;margin-top: 20px;margin-right: 55% ">{{name}}</div>
       </div>

     </van-cell>
     <van-cell title="用户权限"  >{{ identity }}</van-cell>
     <van-cell title="邮箱" >{{email}}</van-cell>
     <van-cell title="电话" >{{ telephone }}</van-cell>
     <van-cell title="地址" >{{address}}</van-cell>
<!--     <van-cell title="标题" label="详细信息" is-link center />-->
   </van-cell-group>
    <van-tabbar v-model="active" class="bottom" inactive-color="black" active-color="#1988f9">
    <van-tabbar-item name="meeting" icon="wap-home-o" to="/meeting">视频会议</van-tabbar-item>
      <!--<van-tabbar-item name="video" icon="video" to="/video">视频回放</van-tabbar-item>-->
      <!--<van-tabbar-item name="photo" icon="photo" to="/photo">图片浏览</van-tabbar-item>-->
     <van-tabbar-item name="user" icon="user-o" to="/user">个人中心</van-tabbar-item>
    </van-tabbar>
   </div>

</template>

<script>

export default {
  name: "user",
  components:{

  },
  data(){
    return{
      active:'user',
      id:'',
      name:'',
      email:'2339897633@qq.com',
      telephone:'',
      identity:'',
      address:'',
      url:require('../assets/images/user.jpg'),
      userInfo: {
        avatar: 'https://img.yzcdn.cn/vant/cat.jpeg', // 替换为实际的头像 URL
        name: 'John Doe', // 替换为实际的用户名
        email: 'john@example.com', // 替换为实际的邮箱地址
      },
    }
  },
  created() {
  this.load();
  },
  methods:{
     async load() {
       //获取localStorage里的数据;
       let user = JSON.parse(localStorage.getItem("user"));
       this.id =user.data.id;
       const base_url = 'https://root.xuxiao888.com'
       //const base_url = 'http://localhost:9090'
       let url = `${base_url}/user`
       //http://localhost:9090/user
       let acc0 = this.$route.query.AccountName || this.$route.query.accountname;//获取当前页面路径

       const res = await this.$axios.get(`${base_url}/user/${this.id}`)
       if (res.data.code == '200') {
         let data=res.data.data;
         console.log(data);
         this.name=data.username;
         this.email=data.email;
         this.identity=data.nickname;
         this.address=data.address;
         this.telephone=data.phone;
         // });
       }

     }
  }
}
</script>

<style scoped>

</style>